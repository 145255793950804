<template>
  <div>
    <v-dialog :value="value" @input="$emit('close')" width="415px" scrollable>
      <v-card>
        <v-card-title class="jc-sb">
          <span class="text-h5">
            {{
              departmentDetails ? departmentDetails.department_title : "Отдел"
            }}
          </span>
          <v-btn icon @click="$emit('close')">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div v-if="staffLoading">
            <v-skeleton-loader
              type="image"
              class="staff-item-skeleton"
            ></v-skeleton-loader>
          </div>
          <div v-else>
            <div v-if="staffList.length === 0" class="text-center mb-3">
              <span class="text-h5 no-staff">
                В данном отделе нету сотрудников
              </span>
            </div>
            <div
              v-else-if="staffList.length > 0"
              v-for="staff in staffList"
              :key="staff.user.id"
              class="staff-item jc-sb"
            >
              <div class="d-flex align-center">
                <v-avatar class="avatar">
                  <v-img v-if="staff.avatar" :src="staff.avatar"></v-img>
                  <v-img v-else src="@/assets/images/avatar-white.svg"></v-img>
                </v-avatar>
                <div class="text">
                  <div class="staff-name">
                    {{ staff.user.first_name }} {{ staff.user.last_name }}
                  </div>
                  <div class="staff-position">{{ staff.position }}</div>
                </div>
              </div>
              <v-btn
                icon
                color="error"
                @click="openDeleteStaff(staff.user)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="openDeleteDepartment" color="error" class="mr-2"> Удалить отдел </v-btn>
          <AddStaffToDepartment
            :departmentDetails="departmentDetails"
            :currentStaffList="staffList"
            @updateStaffFromDialog="emitUpdateStaffInDepartment"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <DeletionConfirm
      v-if="deleteConfirm"
      :title="deleteTitle"
      :description="deleteDescription"
      @close="closeDeletion"
      @delete="emitDelete"
    />
  </div>
</template>

<script>
import AddStaffToDepartment from "@/components/modals/AddStaffToDepartment.vue";
import DeletionConfirm from "@/components/modals/DeletionConfirm.vue";

export default {
  components: {
    AddStaffToDepartment,
    DeletionConfirm,
  },
  props: {
    value: Boolean,
    departmentDetails: Object,
    staffList: Array,
  },
  data: () => ({
    departmentInfoDialog: false,
    staffLoading: true,
    deleteConfirm: false,
    staffToDelete: null,
    deleteTitle: "",
    deleteDescription: "",
  }),
  mounted() {
    this.staffLoaded();
  },
  methods: {
    openModal() {
      this.departmentInfoDialog = true;
    },
    staffLoaded() {
      this.staffLoading = false;
    },
    openDeleteStaff(staff) {
      this.staffToDelete = staff;
      this.deleteTitle = `Вы действительно хотите удалить ${staff.first_name} ${staff.last_name}`;
      this.deleteDescription = `из отдела "${this.departmentDetails.department_title}"?`;
      this.deleteConfirm = true;
    },
    openDeleteDepartment() {
      this.deleteTitle = "Вы действительно хотите удалить отдел";
      this.deleteDescription = `"${this.departmentDetails.department_title}"?`;
      this.deleteConfirm = true;
    },
    closeDeletion() {
      if (this.staffToDelete) {
        this.staffToDelete = null;
      };
      this.deleteConfirm = false;
    },
    emitDelete() {
      if (this.staffToDelete) {
        let staff_id = this.staffToDelete.id;
        let department_id = this.departmentDetails.department_id;
        this.$emit("emitDeleteStaff", { staff_id, department_id });
        this.staffToDelete = null;
      } else {
        let id = this.departmentDetails.id;
        this.$emit("emitDeleteDepartment", { id });
      }
      this.deleteConfirm = false;
    },
    emitUpdateStaffInDepartment() {
      this.staffLoading = true;
      this.$emit("updateStaffList");
    },
  },
};
</script>

<style lang="scss" scoped>
.staff-item {
  margin: 0 auto;
  margin-bottom: 10px;
  padding: 15px;
  width: 350px;
  height: 96px;
  background: rgba(91, 108, 255, 0.04);
  border-radius: 8px;
  box-shadow: none;
  display: flex;
  align-items: center;
}
.avatar {
  margin-right: 20px;
}
.staff-name {
  padding: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #2a2e52;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.staff-position {
  margin-top: 3px;
  padding: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #5b6cff;
}
.v-dialog > .v-card > .v-card__text {
  padding: 10px 24px;
}
.v-dialog > .v-card > .v-card__actions {
  padding: 0 24px 16px;
}
</style>