<template>
  <div>
    <div v-if="departmentsLoading" class="skeleton-card-wrapper">
      <v-skeleton-loader type="image" class="skeleton-card"></v-skeleton-loader>
      <v-skeleton-loader type="image" class="skeleton-card"></v-skeleton-loader>
      <v-skeleton-loader type="image" class="skeleton-card"></v-skeleton-loader>
    </div>
    <div v-else class="card-wrapper">
      <v-container v-if="departmentsOfComplex.length === 0" class="not-added-departments-container">
        <div class="text-h5 not-added-departments"> Вы не добавили ни одного отдела </div>
        <v-btn text color="primary" @click="$emit('openDepartmentAdding')"> + Добавить отдел </v-btn>
      </v-container>
      <v-container v-else-if="departmentsOfComplex.length > 0">
        <v-card
          v-for="department in departmentsOfComplex"
          :key="department.department_id"
          @click="(currentId = department.department_id) && staffLoaded()"
        >
          <v-avatar class="avatar">
            <v-img src="@/assets/images/service.svg"></v-img>
          </v-avatar>
          <v-card-title>
            {{ department.department_title }}
            <v-card-subtitle> Сотрудников: {{ department.staff_count }} </v-card-subtitle>
          </v-card-title>
        </v-card>
      </v-container>
      <DepartmentInfo
        ref="departmentInfoModal"
        :value="currentId >= 0"
        @close="currentId = undefined"
        :departmentDetails="departmentDetails"
        :staffList="staffList"
        @emitDeleteStaff="deleteStaffFromDepartment"
        @emitDeleteDepartment="deleteDepartment"
        @updateStaffList="getDepartmentsOfComplex"
      />
    </div>
  </div>
</template>

<script>
import DepartmentInfo from "@/components/modals/DepartmentInfo.vue";
import {
  getDepartmentsApi,
  getDepartmentsOfComplexApi,
  addDepartmentToComplexApi,
  deleteDepartmentFromComplexApi,
  getStaffByDepartmentApi,
  addStaffToDepartmentApi,
  deleteStaffFromDepartmentApi
} from "@/api/departments";

export default {
  components: {
    DepartmentInfo,
  },
  data: () => ({
    departmentsLoading: true,
    departmentsOfComplex: [],
    currentId: undefined,
  }),
  computed: {
    departmentDetails() {
      return this.departmentsOfComplex.find((dep) => dep.department_id === this.currentId)
    },
    staffList() {
      return this.departmentDetails ? this.departmentDetails.staff : [];
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getDepartmentsOfComplex();
    },
    async getDepartmentsOfComplex() {
      this.departmentsOfComplex = [];
      try {
        let res = await getDepartmentsOfComplexApi();
        let departments = [...res.data];
        departments.forEach((department) => {
          getStaffByDepartmentApi(department.department_id).then((response) => {
            this.departmentsOfComplex = [
              ...this.departmentsOfComplex,
              {
                id: department.id,
                department_id: department.department_id,
                department_code: department.department_code,
                department_title: department.department_title,
                staff: response.data,
                staff_count: response.data.length,
              },
            ];
          });
        });
      } catch (error) {
        throw error;
      } finally {
        this.departmentsLoading = false;
        if (this.currentId) this.staffLoaded();
      };
    },
    staffLoaded() {
      this.$refs.departmentInfoModal.staffLoaded();
    },
    async deleteStaffFromDepartment(data) {
      try {
        let res = await deleteStaffFromDepartmentApi(data);
        this.getDepartmentsOfComplex();
        if (res.data.message) {
          let serverMessage = res.data.message;
          this.$store.commit('SHOW_SNACKBAR', 'deleteStaffSuccessSnackbar');
          this.$store.commit('SET_SNACKBAR_TITLE', serverMessage);
          this.$store.commit('SET_SNACKBAR_COLOR', 'success');
        } else {
          this.$store.commit('SHOW_SNACKBAR', 'deleteStaffSuccessSnackbar');
          this.$store.commit('SET_SNACKBAR_TITLE', 'Сотрудник успешно удалён из отдела');
          this.$store.commit('SET_SNACKBAR_COLOR', 'success');
        }
      } catch (err) {
        this.$store.commit('SHOW_SNACKBAR', 'deleteStaffErrorSnackbar');
        this.$store.commit('SET_SNACKBAR_TITLE', 'Ошибка при удалении сотрудника из отдела');
        this.$store.commit('SET_SNACKBAR_COLOR', 'error');
        throw err;
      };
    },
    async deleteDepartment(id) {
      try {
        await deleteDepartmentFromComplexApi(id);
        this.getDepartmentsOfComplex();
        this.currentId = undefined;
        this.$store.commit('SHOW_SNACKBAR', 'deleteDepartmentSuccessSnackbar');
        this.$store.commit('SET_SNACKBAR_TITLE', 'Отдел успешно удалён');
        this.$store.commit('SET_SNACKBAR_COLOR', 'success');
      } catch (err) {
        this.$store.commit('SHOW_SNACKBAR', 'deleteDepartmentErrorSnackbar');
        this.$store.commit('SET_SNACKBAR_TITLE', 'Ошибка при удалении отдела');
        this.$store.commit('SET_SNACKBAR_COLOR', 'error');
        throw err;
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.not-added-departments-container {
  flex-direction: column;
}
.container > .v-btn {
  margin: 0 auto;
  max-width: 180px;
  border-radius: 8px;
}
.v-card--link:focus:before {
  border-radius: 8px;
}
.not-added-departments {
  margin: 0 auto;
  padding: 15px;
  color: #7f8297;
}
</style>