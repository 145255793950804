<template>
  <v-dialog
    v-model="addStaffToDepartmentDialog"
    @click:outside="closeDialog"
    width="400px"
    scrollable
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-on="on" v-bind="attrs" color="primary" @click="getAllStaff">
        Добавить сотрудника
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="jc-sb">
        <span> Добавить в отдел "{{ departmentDetails ? departmentDetails.department_title : '' }}" </span>
        <v-btn icon @click="closeDialog">
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="card-wrapper">
        <v-skeleton-loader
          v-if="loadingStaff"
          type="list-item-avatar-two-line@3"
          width="350px"
        ></v-skeleton-loader>
        <v-container v-if="!loadingStaff" class="justify-center">
          <div v-if="availableStaffList && availableStaffList.length > 0">
            <v-card
              v-for="staff in availableStaffList"
              :key="staff.user.id"
              class="staff-item jc-sb"
              @click="selectedStaff = staff.user.id"
            >
              <div class="d-flex align-center">
                <v-avatar class="avatar">
                  <v-img v-if="staff.avatar" :src="staff.avatar"></v-img>
                  <v-img v-else src="@/assets/images/avatar-white.svg"></v-img>
                </v-avatar>
                <div class="text">
                  <div class="staff-name">
                    {{ staff.user.first_name }} {{ staff.user.last_name }}
                  </div>
                  <div class="staff-position">{{ staff.position }}</div>
                </div>
              </div>
              <v-icon v-if="selectedStaff == staff.user.id">
                mdi-check-bold
              </v-icon>
            </v-card>
          </div>
          <div v-else>
            <div class="text-h5 no-staff">Нет свободных сотрудников</div>
          </div>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click="addStaffToDepartment(selectedStaff, departmentDetails.department_id)"
          :disabled="selectedStaff === '' || buttonLoading"
          :loading="buttonLoading"
          min-width="240px"
          color="primary"
        >
          Добавить сотрудника в отдел
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { staffApi } from "@/api/staff";
import { addStaffToDepartmentApi } from "@/api/departments";

export default {
  props: {
    departmentDetails: Object,
    currentStaffList: Array,
  },
  data: () => ({
    addStaffToDepartmentDialog: false,
    loadingStaff: true,
    buttonLoading: false,
    allStaffList: [],
    selectedStaff: "",
  }),
  computed: {
    availableStaffList() {
      return this.currentStaffList ? this.allStaffList.filter(
        (allStaff) =>
          !this.currentStaffList.find(
            (currentStaff) => currentStaff.user.id === allStaff.user.id
          )
      ) : [];
    },
  },
  methods: {
    closeDialog() {
      this.selectedStaff = "";
      this.addStaffToDepartmentDialog = false;
    },
    async getAllStaff() {
      this.loadingStaff = true;
      try {
        let res = await staffApi();
        this.allStaffList = res.data;
      } catch (err) {
        console.log(err);
      } finally {
        this.loadingStaff = false;
      }
    },
    async addStaffToDepartment(staff_id, department_id) {
      try {
        this.buttonLoading = true;
        await addStaffToDepartmentApi({
          staff_id: staff_id,
          department_id: department_id,
        });
        this.$store.commit('SHOW_SNACKBAR', 'staffToDepartmentSuccessSnackbar');
        this.$store.commit('SET_SNACKBAR_TITLE', 'Сотрудник успешно добавлен в отдел');
        this.$store.commit('SET_SNACKBAR_COLOR', 'success');
        this.$emit('updateStaffFromDialog');
      } catch (err) {
        let errorMessage = err.response.data.message;
        if (errorMessage === "Given user is not staff") {
          this.$store.commit('SHOW_SNACKBAR', 'notStaffToDepartmentErrorSnackbar');
          this.$store.commit('SET_SNACKBAR_TITLE', 'Выбранный пользователь не является сотрудником!');
          this.$store.commit('SET_SNACKBAR_COLOR', 'error');
        } else {
          this.$store.commit('SHOW_SNACKBAR', 'staffToDepartmentErrorSnackbar');
          this.$store.commit('SET_SNACKBAR_TITLE', errorMessage);
          this.$store.commit('SET_SNACKBAR_COLOR', 'error');
        };
      } finally {
        this.closeDialog();
        this.buttonLoading = false;
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.v-skeleton-loader {
  margin: 0 auto;
}
.v-card--link:focus:before {
  border-radius: 8px;
}
.staff-item {
  margin: 0 auto !important;
  margin-bottom: 10px !important;
  padding: 15px;
  width: 350px;
  height: 96px;
  background: rgba(91, 108, 255, 0.04);
  border-radius: 8px;
  box-shadow: none !important;
  display: flex;
  align-items: center;
}
.staff-item:hover {
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%),
    0px 1px 5px 0px rgb(0 0 0 / 12%) !important;
}
.avatar {
  margin-right: 20px;
}
.staff-name {
  padding: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #2a2e52;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.staff-position {
  margin-top: 3px;
  padding: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #5b6cff;
}
.no-staff {
  padding-top: 15px;
  height: 96px;
  color: #7f8297;
}
.v-dialog > .v-card > .v-card__text {
  padding: 10px 24px;
}
.v-dialog > .v-card > .v-card__actions {
  padding: 0 24px 16px;
}
</style>